import React, {useContext} from 'react';
import {Link, useParams} from "react-router-dom";
import { MetadataContext } from './Contexts';
import { CreditFromPage } from './Credits';
import TextLink from './TextLink';
import SmallTitle from './SmallTitle.png';

function pad(num) {
    var str = String(num);
    const zeroes = 3 - str.length;
    for (var i = 0; i < zeroes; i++) {
        str = "0" + str;
    }
    return str;
}

function ComicPage() {
    const metadata = useContext(MetadataContext);
    const pageNumber = useParams('comicpage').comicpage;
    var pageNumberInt = parseInt(pageNumber, 10);
    pageNumberInt = isNaN(pageNumberInt) ? -1 : pageNumberInt;

    const isFirstPage = (pageNumberInt === 1);
    var isLastPage = false;
    if (metadata) {
        if (pageNumberInt > metadata.lastPage || pageNumberInt <= 0) {
            document.location="/404";
            return null;
        } else {
            isLastPage = (pageNumberInt === metadata.lastPage);
        }
    } else {
        return null;
    }

    return (
        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
            <Link to="/">
                <img src={SmallTitle} alt="Spy Shit"/>
            </Link>
            <div className="spyshit-text"> Page {pageNumberInt} by <CreditFromPage page={pageNumberInt} metadata={metadata}/> </div>
            <Link to={`/pages/${isLastPage ? pageNumberInt :pageNumberInt + 1}`}>
                <img 
                    src={`https://spyshit.s3-us-west-2.amazonaws.com/pages/${pad(pageNumberInt)}.png`}
                    alt="Page"
                />
            </Link>
            <div style={{display: "flex", justifyContent: "center", margin: "10px"}}>
                <Button to={`/pages/1`} disabled={isFirstPage}> First </Button>
                <Button to={`/pages/${pageNumberInt - 1}`} disabled={isFirstPage}> Back </Button>
                <Button to={`/pages/${pageNumberInt + 1}`} disabled={isLastPage}> Next </Button>
                <Button to={`/pages/${metadata.lastPage}`} disabled={isLastPage}> Last </Button>
            </div>
            <TextLink to="/"> Home </TextLink>
        </div>
    );
}

function Button(props) {
    return <div style={{
        marginLeft: '20px',
        marginRight: '20px'
    }}>
        <TextLink {...props}>
            {props.children}
        </TextLink>
    </div>
}

export default ComicPage;
