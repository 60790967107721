import React from 'react';
import { getBookIndexFromPage } from './BookUtil';

export const CreditFromPage = ({ page, metadata}) => {
    var guestAuthorEntries = Object.entries(metadata.authors).filter(authorEntry => authorEntry[1].guestPages.includes(page));
    if (guestAuthorEntries.length > 0) {
        return <Credit name={guestAuthorEntries[0][1].longName} link={guestAuthorEntries[0][1].link} />;
    }

    const book = getBookIndexFromPage(page, metadata);
    const firstPageOfBook = metadata.books[book].firstPage;
    const guestPagesInBookBeforePage = Object.entries(metadata.authors).map(authorEntry => authorEntry[1].guestPages.filter(guestPage => guestPage < page).length).reduce((a, b) => a + b);
    const authorIndex = Math.floor((page - firstPageOfBook - guestPagesInBookBeforePage) / 2) % 3;
    const author = metadata.authors[metadata.books[book].authorOrder[authorIndex]];
    return <Credit name={author.longName} link={author.link} />;
}

export const CreditFromName = ({ name, metadata }) => {
    const authorInfo = metadata.authors[name];
    return <Credit name={authorInfo.longName} link={authorInfo.link}/>
}

function Credit(props) {
    return <a href={props.link}>{props.name}</a>;
}